<template>
  <div>
    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          v-model="value.name"
          class="w-full mt-4"
          label="Title"
          v-validate="'required'"
          name="name"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('name')">
          {{ errors.first('name') }}
        </span>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vs-component vs-con-input-label vs-input w-full mt-4 vs-input-primary">
          <label class="vs-input--label">Status</label>
          <v-select
            v-model="value.is_active"
            :clearable="false"
            :options="statusOptions"
            :reduce="status => status.value"
            v-validate="'required'"
            name="status"/>
          <span class="text-danger text-sm block mt-2" v-show="errors.has('is_active')">
            {{ errors.first('is_active') }}
          </span>
        </div>
      </div>
    </div>

    <div class="vx-row" v-if="value.id">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="dateDisplay(value.created_at)"
          class="w-full mt-4"
          label="Created At"
          disabled/>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="dateDisplay(value.updated_at)"
          class="w-full mt-4"
          label="Updated At"
          disabled/>
      </div>
    </div>
  </div>

</template>

<script>
import { default as infoMixin } from '@/mixins/infoMixin'
import { default as formMixin } from '@/mixins/sample-set/formMixin'

export default {
  name: 'SampleSetGeneral',
  mixins: [infoMixin, formMixin],
  props: {
    value: {
      type: Object,
    },
  },
}
</script>
