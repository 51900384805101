
import { mapActions, mapGetters } from 'vuex'

export default {
  // data:() => ({
  //   //typeOptions: constants.typeOptions
  // }),

  computed: {
    ...mapGetters('data', ['giftSets']),

    giftSetsOptions () {
      if (this.lodash.get(this, 'giftSets', []).length === 0) {
        return []
      }

      const giftSets = this.lodash.concat(
        this.default.parent,
        this.giftSet.map((giftSet) => ({
          label: giftSet.title,
          value: giftSet.id,
        })),
      )

      return giftSets
    },
  },

  methods: {
    ...mapActions('data', ['fetchGiftSets']),
  },

  beforeMount () {
    if (this.lodash.get(this, 'giftSets', []).length === 0) {
      this.fetchGiftSets()
    }
  },
}
